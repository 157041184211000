// Library
import { Routes, Route, Navigate } from 'react-router-dom';
import React, { ReactNode } from 'react';
import Overlay from './components/common/Overlay';
import Auth from './pages/Auth';
import ExperiencesView from './pages/ExperiencesView';
import ProcessesView from './pages/ProcessesView';
import PluginsView from './pages/PluginsView';
import BillingView from './pages/BillingView';
import Viewer from './pages/Viewer';
import UnsubscribeEmail from './pages/UnsubscribeEmail';
import { useAuthStore } from './store/auth';
import { AuthorizedRoute } from './AuthorizedRoute';
import InternalRedirect from './pages/InternalRedirect';
import { useBreakpointValue } from '@chakra-ui/react';
import ScreenSizeWarning from './components/common/ScreenSizeWarning';
import CommunityExperiencesView from './pages/CommunityExperiencesView';
import CommunityProfileView from './pages/CommunityProfileView';

interface AuthRoute {
  path: string;
  element: ReactNode;
  isProtected?: boolean;
}

const App = () => {
  const { isLoggedIn } = useAuthStore((state) => state);

  const authRoutes: AuthRoute[] = [
    { path: '/', element: <ExperiencesView /> },
    { path: '/plugins', element: <PluginsView /> },
    { path: '/billing', element: <BillingView />, isProtected: true },
    { path: '/processes', element: <ProcessesView /> },
    { path: '/viewer/:experienceId', element: <Viewer /> },
    { path: '/community', element: <CommunityExperiencesView /> },
    { path: '/community/community-profile', element: <CommunityProfileView /> },
    { path: '/*', element: <Navigate to='/' /> },
  ];

  const isSmallScreen = useBreakpointValue({ base: true, sm: true, lg: false });

  if (isSmallScreen) return <ScreenSizeWarning />;

  return (
    <>
      {/* Public Route */}
      <Routes>
        <Route path='/unsubscribe-email' element={<UnsubscribeEmail />} />

        {/* Authenticated Routes */}
        {isLoggedIn ? (
          <>
            {authRoutes.map(({ path, element, isProtected }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Overlay>
                    {isProtected ? (
                      <AuthorizedRoute>{element}</AuthorizedRoute>
                    ) : (
                      element
                    )}
                  </Overlay>
                }
              />
            ))}
          </>
        ) : (
          <>
            <Route path='/login' element={<Auth />} />
            <Route path='/internal' element={<InternalRedirect />} />
            <Route path='/*' element={<Navigate to='/login' />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default App;
