import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { customDashTheme } from './theme';
import { ChakraProvider } from '@chakra-ui/react';

const root = ReactDOM.createRoot(
  document.getElementById('dashboard-root') as HTMLElement
);

root.render(
  <ChakraProvider theme={customDashTheme} cssVarsRoot='#dashboard-root'>
    <HashRouter>
      <App />
    </HashRouter>
  </ChakraProvider>
);

reportWebVitals();
